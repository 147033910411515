import { Subscription } from "./constant";
const initial_state = {
    data: [],
    message: "",
    loading: false,
};

export const getSubscriptionReducer = (state = initial_state, action) => {
    switch (action.type) {
        case Subscription.GET_SUBSCRIPTION_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case Subscription.GET_SUBSCRIPTION_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case Subscription.GET_SUBSCRIPTION_ERROR:
            return {
                data: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };
    }
};

export const addSubscriptionReducer = (state = initial_state, action) => {
    switch (action.type) {
        case Subscription.UPDATE_SUBSCRIPTION_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case Subscription.UPDATE_SUBSCRIPTION_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case Subscription.UPDATE_SUBSCRIPTION_RESET:
            return {
                data: initial_state.data,
                loading: false,
            };
        case Subscription.UPDATE_SUBSCRIPTION_ERROR:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};
export const holdSubscriptionReducer = (state = initial_state, action) => {
    switch (action.type) {
        case Subscription.HOLD_SUBSCRIPTION_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case Subscription.HOLD_SUBSCRIPTION_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case Subscription.HOLD_SUBSCRIPTION_RESET:
            return {
                data: initial_state.data,
                loading: false,
            };
        case Subscription.HOLD_SUBSCRIPTION_ERROR:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};
export const renewSubscriptionReducer = (state = initial_state, action) => {
    switch (action.type) {
        case Subscription.RENEW_SUBSCRIPTION_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case Subscription.RENEW_SUBSCRIPTION_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case Subscription.RENEW_SUBSCRIPTION_RESET:
            return {
                data: initial_state.data,
                loading: false,
            };
        case Subscription.RENEW_SUBSCRIPTION_ERROR:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export const updateCurrentPlanReducers = (state = initial_state, action) => {
    switch (action.type) {
        case Subscription.UPGRADE_CURRENT_PLANS_DETAILS_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case Subscription.UPGRADE_CURRENT_PLANS_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.payload,  // Ensure you directly assign payload here
                loading: false,
            };
        case Subscription.UPGRADE_CURRENT_PLANS_DETAILS_RESET:
            return {
                data: action?.payload,
                loading: false,
            };
        case Subscription.UPGRADE_CURRENT_PLANS_DETAILS_ERROR:
            return {
                data: state,
                loading: false,
            };
        default:
            return { ...state };
    }
};
