import { APICore } from "../../../helpers/api/apiCore";
import * as URL from "../../../helpers/api/apiEndPoint";

const api = new APICore();

export function getTicketApi(params) {
  const { filterData } = params;
  return api.get(`${URL.GET_TICKET}?filterData=${filterData}&limit=6&page=1`);
}

export function getTicketByIdApi(params) {
  return api.get(`${URL.GET_TICKET_BY_ID}/${params?.ticketId}`);
}

export function updateReplyTicketApi(params) {
  const { data } = params;
  return api.update(`${URL.UPDATE_TICKET}`, params);
}
