import * as URL from "../../../helpers/api/apiEndPoint";
import { APICore } from "../../../helpers/api/apiCore";
const api = new APICore();

export function getSubscriptionApiEndPoint(data): any {
    return api.get(`${URL.GET_SUBSCRIPTION_REQUEST}type=${data?.payload?.type}&skip=${data?.payload?.skip}&limit=${data?.payload?.limit}`);
}

export function postSubscriptionApiEndPoint(data): any {
    return api.update(URL.UPDATE_SUBSCRIPTION, data?.payload);
}
export function holdSubscriptionApiEndPoint(data): any {
    return api.update(URL.HOLD_SUBSCRIPTION, data?.payload);
}
export function renewSubscriptionApiEndPoint(data): any {
    return api.update(URL.RENEW_SUBSCRIPTION, data?.payload);
}
export function upgradeCurrentPlanEndPoint(data) {
    console.log(data, "ytfghjiuytfvb");

    return api.update(`${URL.UPGRADE_SUBSCRIPTION}`, { subscriptionId: data?.subscriptionId, clientId: data?.clientId })
}
