import { ticket } from "./constant";

const initial_state = {
  data: [],
  message: "",
  loading: false,
};

export const getTicketReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ticket.GET_TICKET_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case ticket.GET_TICKET_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case ticket.GET_TICKET_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const getTicketByIdReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ticket.GET_TICKET_BY_ID_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case ticket.GET_TICKET_BY_ID_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case ticket.GET_TICKET_BY_ID_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const updateReplyTicketReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ticket.UPDATE_REPLY_TICKET_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case ticket.UPDATE_REPLY_TICKET_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case ticket.UPDATE_REPLY_TICKET_RESET:
      return initial_state;
    case ticket.UPDATE_REPLY_TICKET_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};
