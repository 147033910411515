// @flow
import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
// import layoutSaga from "./layout/saga";
// import businessEmailSaga from "./auth/businessEmail/saga";
// import otpSendEmailSaga from "./auth/otpSendEmail/saga";
// import verifyDetailSaga from "./auth/verifyDetail/saga";
// import ProfileSaga from "../redux/accountPro/profile/saga";
// import resetPasswordSaga from "./auth/resetPassword/saga";
// import subAccountantSaga from "./subAccountant/saga";
// import authComponentDataSendSaga from "./ComponentDataSend/authComponentDataSend/saga";
// import organisationSaga from "./accountPro/organisation/saga";

// new code
// import signupAccountSaga from "./auth/accountant/saga";
// import accountDetailSaga from "./accountant/profile/acountDetail/saga";
// import accountantSearchSaga from "./accountant/searchAccountant/saga";

// Super Admin
import AnncouncementsSaga from "./superAdmin/Announcement/saga";
import AccountantSaga from "./superAdmin/Accountant/saga";
import clientSaga from "./superAdmin/Clients/saga";
import allLanguagesSaga from "./superAdmin/Languages/saga";
import ChargeSaga from "./superAdmin/Charges/saga";
import paymentsSaga from "./superAdmin/Payments/saga";
import allTicketSaga from "./superAdmin/ticket/saga";
import allSubscriptionSaga from "./superAdmin/Subscription/saga";
export default function* rootSaga(): any {
  yield all([
    authSaga(),
    clientSaga(),
    // layoutSaga(),
    // businessEmailSaga(),
    // otpSendEmailSaga(),
    // verifyDetailSaga(),
    // ProfileSaga(),
    // resetPasswordSaga(),
    // subAccountantSaga(),
    // authComponentDataSendSaga(),
    // signupAccountSaga(),
    // accountDetailSaga(),
    // organisationSaga(),
    // accountantSearchSaga()
    AnncouncementsSaga(),
    AccountantSaga(),
    allLanguagesSaga(),
    ChargeSaga(),
    paymentsSaga(),
    allTicketSaga(),
    allSubscriptionSaga(),
  ]);
}
