import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";
import * as layoutConstants from "../constants/layout";

// All layouts/containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal";
import FullLayout from "../layouts/Full";
import ErrorPageNotFound from "../helpers/pageNotFound/ErrorPageNotFound";
// lazy load all the views

// auth
// new code 13-12-2023
const Logout = React.lazy(() => import("../pages/account/Logout"));
const AuthIndex = React.lazy(() => import("../auth/AuthIndex"));

const SignupBusinessForm = React.lazy(() =>
  import("../auth/signup/signupBusinessEmail/SignupBusinessEmailForm")
);
const SignupVerifyDetailForm = React.lazy(() =>
  import("../auth/signup/signupVerifyDetail/SignupVerifyDetailForm")
);
const SignupCreatePasswordForm = React.lazy(() =>
  import("../auth/signup/signupCreatePassword/SignupCreatePasswordForm")
);
const SignupOtpSendEmailForm = React.lazy(() =>
  import("../auth/signup/signupOtpSendEmail/SignupOtpSendEmailForm")
);
const WelcomeAccount = React.lazy(() =>
  import("../auth/Welcome/WelcomeAccount")
);
const ForgetPasswordEmail = React.lazy(() =>
  import("../auth/forgetPassword/ForgetPasswordEmail")
);
const ForgetPasswordVerify = React.lazy(() =>
  import("../auth/forgetPassword/ForgetPasswordVerify")
);
const SubClientDeactiveModel = React.lazy(() =>
  import("../auth/Logout/SubClientDeactiveModel")
);

// new code 13-12-2023
// auth

// // dashboard
const EcommerceDashboard = React.lazy(() =>
  import("../pages/dashboard/Ecommerce")
);
// const OrganisationIndex = React.lazy(() =>
//   import("../pages/accountpro/organisation/OrganisationIndex")
// );
// const SubUser = React.lazy(() =>
//   import("../pages/accountpro/subAccountant/SubAccountantIndex")
// );
// const Myaccount = React.lazy(() =>
//   import("../pages/accountpro/profile/profileIndex")
// );
// const AccountantTable=React.lazy(() =>
// import("../pages/accountpro/searchAccountant/AccountantList/AccountantTable")

// const AccountantTable = React.lazy(() =>
//   import(
//     "../pages/accountpro/searchAccountant/AccountantList/AccountantSearchTable"
//   )
// );
const AccessDeniedPage = React.lazy(() =>
  import("../auth/accessDenied/AccessDeniedPage")
);
// // dashboard

// Super Admin Pages

const AnnouncementIndex = React.lazy(() =>
  import("../pages/SuperAdmin/Announcements/AnnouncementIndex")
);
const LanguageIndex = React.lazy(() =>
  import("../pages/SuperAdmin/Languages/LanguageIndex")
);
const ClientIndex = React.lazy(() =>
  import("../pages/SuperAdmin/Clients/ClientIndex")
);
const ImportAccountantsIndex = React.lazy(() =>
  import("../pages/SuperAdmin/Importaccountants/ImportAccountantsIndex")
);
const AccountantIndex = React.lazy(() =>
  import("../pages/SuperAdmin/Accountant/AccountantIndex")
);
const ChargesIndex = React.lazy(() =>
  import("../pages/SuperAdmin/Charges/ChargesIndex")
);
const PaymentsIndex = React.lazy(() =>
  import("../pages/SuperAdmin/Payments/PaymentsIndex")
);
const HelpAndSupport = React.lazy(() =>
  import("../pages/SuperAdmin/Help&Support/Help&SupportIndex")
);
const CancelSubscriptions = React.lazy(() =>
  import("../pages/SuperAdmin/Subscription/SubscriptionIndex")
);
const SpecificTicketPage = React.lazy(() =>
  import(
    "../pages/SuperAdmin/Help&Support/SpecificTicketPage/SpecificTicketPage"
  )
);
// const Graph = React.lazy(() => import("../pages/graph/Graph"));
const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { layout } = useSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case layoutConstants.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  const authorizationRole = useSelector((state) => state?.Auth?.user?.role);

  return useRoutes([
    { path: "/", element: <Root /> },
    {
      // public routes
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "superadmin",
          children: [
            { path: "login", element: <LoadComponent component={AuthIndex} /> },
            // {
            //   path: "signup",
            //   element: <LoadComponent component={SignupBusinessForm} />,
            // },
            // {
            //   path: "signupVerify",
            //   element: <LoadComponent component={SignupVerifyDetailForm} />,
            // },
            // {
            //   path: "signupCreatePassword",
            //   element: <LoadComponent component={SignupCreatePasswordForm} />,
            // },
            // {
            //   path: "signupOtpSend/:id/:token",
            //   element: <LoadComponent component={SignupOtpSendEmailForm} />,
            // },
            // {
            //   path: "welcomeAccount",
            //   element: <LoadComponent component={WelcomeAccount} />,
            // },
            // {
            //   path: "forgetPasswordEmail",
            //   element: <LoadComponent component={ForgetPasswordEmail} />,
            // },
            // {
            //   path: "reset-password",
            //   element: <LoadComponent component={ForgetPasswordVerify} />,
            // },
            // {
            //   path: "reset-password/:id",
            //   element: <LoadComponent component={ForgetPasswordVerify} />,
            // },
            { path: "logout", element: <LoadComponent component={Logout} /> },
            {
              path: "accessDeniedPage",
              element: <LoadComponent component={AccessDeniedPage} />,
            },
            // {
            //   path: "accountLock",
            //   element: <LoadComponent component={SubClientDeactiveModel} />,
            // },
            {
              path: "*",
              element: <LoadComponent component={ErrorPageNotFound} />,
            },
          ],
        },
      ],
    },

    {
      // auth protected routes
      path: "/",
      element: <PrivateRoute roles={authorizationRole} component={Layout} />,
      children: [
        {
          path: "dashboard",
          children: [
            {
              path: "ecommerce",
              element: <LoadComponent component={EcommerceDashboard} />,
            },
            {
              path: "*", // Catch all for dashboard routes
              element: <LoadComponent component={ErrorPageNotFound} />,
            },
          ],
        },
        {
          path: "announcements",
          element: <LoadComponent component={AnnouncementIndex} />,
        },
        {
          path: "clients",
          element: <LoadComponent component={ClientIndex} />,
        },
        {
          path: "accountants",
          element: <LoadComponent component={AccountantIndex} />,
        },
        {
          path: "import-accountants",
          element: <LoadComponent component={ImportAccountantsIndex} />,
        },
        {
          path: "languages",
          element: <LoadComponent component={LanguageIndex} />,
        },
        {
          path: "charges",
          element: <LoadComponent component={ChargesIndex} />,
        },
        {
          path: "payments",
          element: <LoadComponent component={PaymentsIndex} />,
        },
        {
          path: "help&support",
          element: <LoadComponent component={HelpAndSupport} />,
        },
        {
          path: "help&support/:id",
          element: <LoadComponent component={SpecificTicketPage} />,
        },
        {
          path: "cancelSubscription",
          element: <LoadComponent component={CancelSubscriptions} />,
        },
        {
          path: "*", // Catch all for root routes
          element: <LoadComponent component={ErrorPageNotFound} />,
        },
      ],
    },
  ]);
};

export { AllRoutes };
