import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { Subscription } from "./constant";
import { getSubscriptionApiEndPoint, holdSubscriptionApiEndPoint, postSubscriptionApiEndPoint, renewSubscriptionApiEndPoint, upgradeCurrentPlanEndPoint } from "./api";

function* getSubscriptionFunction({ payload }) {
    try {
        yield put({
            type: Subscription.GET_SUBSCRIPTION_LOADING,
            payload: {},
        });
        const response = yield call(getSubscriptionApiEndPoint, { payload });
        if (response.data.status) {
            yield put({
                type: Subscription.GET_SUBSCRIPTION_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: Subscription.GET_SUBSCRIPTION_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: Subscription.GET_SUBSCRIPTION_ERROR,
            payload: error?.data,
        });
        yield put({
            type: Subscription.GET_SUBSCRIPTION_RESET,
            payload: {},
        });
    }
}

function* addSubscriptionFunction({ payload }) {

    try {
        yield put({
            type: Subscription.UPDATE_SUBSCRIPTION_LOADING,
            payload: {},
        });
        const response = yield call(postSubscriptionApiEndPoint, { payload });
        if (response.data.status) {
            yield put({
                type: Subscription.UPDATE_SUBSCRIPTION_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: Subscription.UPDATE_SUBSCRIPTION_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: Subscription.UPDATE_SUBSCRIPTION_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: Subscription.UPDATE_SUBSCRIPTION_ERROR,
            payload: error?.data,
        });
        yield put({
            type: Subscription.UPDATE_SUBSCRIPTION_RESET,
            payload: {},
        });

    }
}

function* holdSubscriptionFunction({ payload }) {

    try {
        yield put({
            type: Subscription.HOLD_SUBSCRIPTION_LOADING,
            payload: {},
        });
        const response = yield call(holdSubscriptionApiEndPoint, { payload });
        if (response.data.status) {
            yield put({
                type: Subscription.HOLD_SUBSCRIPTION_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: Subscription.HOLD_SUBSCRIPTION_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: Subscription.HOLD_SUBSCRIPTION_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: Subscription.HOLD_SUBSCRIPTION_ERROR,
            payload: error?.data,
        });
        yield put({
            type: Subscription.HOLD_SUBSCRIPTION_RESET,
            payload: {},
        });

    }
}
function* renewSubscriptionFunction({ payload }) {

    try {
        yield put({
            type: Subscription.RENEW_SUBSCRIPTION_LOADING,
            payload: {},
        });
        const response = yield call(renewSubscriptionApiEndPoint, { payload });
        if (response.data.status) {
            yield put({
                type: Subscription.RENEW_SUBSCRIPTION_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: Subscription.RENEW_SUBSCRIPTION_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: Subscription.RENEW_SUBSCRIPTION_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: Subscription.RENEW_SUBSCRIPTION_ERROR,
            payload: error?.data,
        });
        yield put({
            type: Subscription.RENEW_SUBSCRIPTION_RESET,
            payload: {},
        });
    }
}

function* upgradeCurrentPlanFunction({ payload }) {
    try {
        yield put({
            type: Subscription.UPGRADE_CURRENT_PLANS_DETAILS_LOADING,
        });

        // Pass payload directly if it's the correct structure
        const response = yield call(upgradeCurrentPlanEndPoint, payload);

        if (response.data.status) {
            yield put({
                type: Subscription.UPGRADE_CURRENT_PLANS_DETAILS_SUCCESS,
                payload: response.data,
            });
            yield put({
                type: Subscription.UPGRADE_CURRENT_PLANS_DETAILS_RESET,
            });
        } else {
            yield put({
                type: Subscription.UPGRADE_CURRENT_PLANS_DETAILS_ERROR,
                payload: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: Subscription.UPGRADE_CURRENT_PLANS_DETAILS_ERROR,
            payload: error?.response?.data || error.message,  // Ensure you're accessing the correct error data
        });
    }
}



export function* actionSubscriptionDetail(): any {
    yield takeEvery(
        Subscription.GET_SUBSCRIPTION,
        getSubscriptionFunction
    );
}
export function* actionAddSubscriptionDetail(): any {
    yield takeEvery(
        Subscription.UPDATE_SUBSCRIPTION,
        addSubscriptionFunction
    );
}
export function* actionRenewSubscriptionDetail(): any {
    yield takeEvery(
        Subscription.RENEW_SUBSCRIPTION,
        renewSubscriptionFunction
    );
}
export function* actionHoldSubscriptionDetail(): any {
    yield takeEvery(
        Subscription.HOLD_SUBSCRIPTION,
        holdSubscriptionFunction
    );
}

export function* upgradeCurrentPlaneWatcher(): any {
    yield takeEvery(Subscription.UPGRADE_CURRENT_PLANS_DETAILS, upgradeCurrentPlanFunction);
}

function* allSubscriptionSaga(): any {
    yield all([
        fork(actionSubscriptionDetail),
        fork(actionAddSubscriptionDetail),
        fork(actionRenewSubscriptionDetail),
        fork(actionHoldSubscriptionDetail),
        fork(upgradeCurrentPlaneWatcher),
    ]);
}
export default allSubscriptionSaga;
