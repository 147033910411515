export const Subscription = {
    //   GET
    GET_SUBSCRIPTION: "GET_SUBSCRIPTION",
    GET_SUBSCRIPTION_LOADING: "GET_SUBSCRIPTION_LOADING",
    GET_SUBSCRIPTION_SUCCESS: "GET_SUBSCRIPTION_SUCCESS",
    GET_SUBSCRIPTION_ERROR: "GET_SUBSCRIPTION_ERROR",
    GET_SUBSCRIPTION_RESET: "GET_SUBSCRIPTION_RESET",
    //   POST
    UPDATE_SUBSCRIPTION: "UPDATE_SUBSCRIPTION",
    UPDATE_SUBSCRIPTION_LOADING: "UPDATE_SUBSCRIPTION_LOADING",
    UPDATE_SUBSCRIPTION_SUCCESS: "UPDATE_SUBSCRIPTION_SUCCESS",
    UPDATE_SUBSCRIPTION_ERROR: "UPDATE_SUBSCRIPTION_ERROR",
    UPDATE_SUBSCRIPTION_RESET: "UPDATE_SUBSCRIPTION_RESET",

    HOLD_SUBSCRIPTION: "HOLD_SUBSCRIPTION",
    HOLD_SUBSCRIPTION_LOADING: "HOLD_SUBSCRIPTION_LOADING",
    HOLD_SUBSCRIPTION_SUCCESS: "HOLD_SUBSCRIPTION_SUCCESS",
    HOLD_SUBSCRIPTION_ERROR: "HOLD_SUBSCRIPTION_ERROR",
    HOLD_SUBSCRIPTION_RESET: "HOLD_SUBSCRIPTION_RESET",

    RENEW_SUBSCRIPTION: "RENEW_SUBSCRIPTION",
    RENEW_SUBSCRIPTION_LOADING: "RENEW_SUBSCRIPTION_LOADING",
    RENEW_SUBSCRIPTION_SUCCESS: "RENEW_SUBSCRIPTION_SUCCESS",
    RENEW_SUBSCRIPTION_ERROR: "RENEW_SUBSCRIPTION_ERROR",
    RENEW_SUBSCRIPTION_RESET: "RENEW_SUBSCRIPTION_RESET",

    UPGRADE_CURRENT_PLANS_DETAILS: "UPGRADE_CURRENT_PLANS_DETAILS",
    UPGRADE_CURRENT_PLANS_DETAILS_LOADING: "UPGRADE_CURRENT_PLANS_DETAILS_LOADING",
    UPGRADE_CURRENT_PLANS_DETAILS_SUCCESS: "UPGRADE_CURRENT_PLANS_DETAILS_SUCCESS",
    UPGRADE_CURRENT_PLANS_DETAILS_ERROR: "UPGRADE_CURRENT_PLANS_DETAILS_ERROR",
    UPGRADE_CURRENT_PLANS_DETAILS_RESET: "UPGRADE_CURRENT_PLANS_DETAILS_RESET",

};
